import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Button } from "react-bootstrap";

export const LogoutButton = () => {
  const { logout } = useAuth0();

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  return (
    <Button className="btn btn-danger" onClick={handleLogout} style={{width:"90%", marginLeft:"5%", borderRadius: "50px", padding: "8px 20px", marginRight: "4px"}}>
      Log Out
    </Button>
  );
};