import React from "react";
import NavbarJS from "./navigation/navbar";
import Footer from "./navigation/footer";

export const PageLayout = ({ children }) => {
  return (
    <div className="page-layout">
      <NavbarJS />
      <div className="page-layout__content">{children}</div>
      <Footer />
    </div>
  );
};
