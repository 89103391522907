import ImageDownloader from '../../components/forms/ImageDownloader';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import LoadingWheel from '../../components/loading';
import { PageLayout } from "../../components/page-layout";
import { useAuth0 } from '@auth0/auth0-react';
import { BsPencilSquare } from "react-icons/bs";
import { Button } from "react-bootstrap";
import DeleteModal from '../../components/modals/deleteModal';
import PdfThumbnail from '../../components/pdf/PdfThumbnail';
import * as pdfjsLib from 'pdfjs-dist';
import PdfEmbed from '../../components/pdf/PdfDisplay';

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

function ViewItem() {
  const id = window.location.pathname.split('/').reverse()[0];
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [error, setError] = useState(null);
  const [imagesHtml, setImagesHtml] = useState([]);
  const [itemName, setItemName] = useState('');
  const [itemDescription, setItemDescription] = useState('');
  const [userName, setUserName] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [itemEditedName, setItemEditedName] = useState('');
  const [itemEditedDescription, setItemEditedDescription] = useState('');
  const [topicName, setTopicName] = useState('');
  const { getAccessTokenSilently, user } = useAuth0();

  useEffect(() => {
    setLoading(true);
    axios.get(`${process.env.REACT_APP_API_SERVER_URL}/v1/items/` + id)
      .then(response => {
        setItems(response.data);
        setItemName(response.data.name);
        setItemDescription(response.data.description);
        setItemEditedName(response.data.name);
        setItemEditedDescription(response.data.description);
        setUserName(response.data.user);
        setTopicName(response.data.topicName || null);
        if (response.data.images.length > 0) {
          setImagesHtml(response.data.images[0].file);
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  // Validation function to check if inputs are valid
  const validateForm = () => {
    if (!itemEditedName.trim()) {
      return 'Resource name cannot be empty';
    }
    if (!itemEditedDescription.trim()) {
      return 'Resource description cannot be empty';
    }
    return ''; // No errors
  };

  const handleSubmit = async () => {
    setError(null);
    // Validate the inputs
    const validationError = validateForm();
    if (validationError) {
      alert(validationError);
      return;
    }

    // Check if there's a change in the fields
    if (itemName === itemEditedName && itemDescription === itemEditedDescription) {
      toggleEdit();
      return;
    }

    try {
      const formData = new FormData();
      if (itemName !== itemEditedName) {
        formData.append('name', itemEditedName);
      }
      if (itemDescription !== itemEditedDescription) {
        formData.append('description', itemEditedDescription);
      }

      const accessToken = await getAccessTokenSilently();

      await axios.post(`${process.env.REACT_APP_API_SERVER_URL}/v1/items/update/` + id, formData, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        }
      });

      // Update the item with new changes
      setItemName(itemEditedName);
      setItemDescription(itemEditedDescription);
      setIsEditing(false);
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Error submitting form. Please try again.');
    }
  };

  if (loading) {
    return <LoadingWheel />;
  }

  if (error || !items) {
    return <PageLayout><h1>404: Item not found</h1></PageLayout>;
  }

  return (
    <PageLayout>
      <div className='container'>
        {user && user.username === userName && (
          <span style={{ float: "right", padding: "4%" }}>
            <Button onClick={toggleEdit}><BsPencilSquare /></Button>{'\u00A0'}
            {isEditing && <Button onClick={handleSubmit}>Save</Button>}{'\u00A0'}
            <DeleteModal deleteId={id} />
          </span>
        )}

        <h1>{itemName}</h1>
        <h3>{topicName}</h3>

        <p>Created By: {userName}</p>
        <hr />

        {!isEditing ? (
          <p>{itemDescription}</p>
        ) : (
          <div className="form-group">
            <textarea
              name="itemDescription"
              id="itemDescription"
              value={itemEditedDescription}
              style={{padding:"5px", width:"100%", borderRadius:"8px"}}
              onChange={(e) => setItemEditedDescription(e.target.value)}
              required
            />
          </div>
        )}

<hr />

        <p style={{fontSize:"0.8rem"}}>PDFs may be slow to load, if not loaded please refresh the page</p>
        <div className="pdfView pdfThumb">
          {imagesHtml ? (
            <PdfThumbnail pdfUrl={imagesHtml} showAllPages={true} />
          ) : (
            <p>File is not a PDF</p>
          )}
        </div>
        <div className="pdfView pdfEmbed">
          {imagesHtml ? (
            <PdfEmbed pdfUrl={imagesHtml} showAllPages={true} />
          ) : (
            <p>File is not a PDF</p>
          )}
        </div>
        <br />

        <div onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
  <ImageDownloader imageData={{ images: [imagesHtml], fileName: itemName }} />
</div>
        
      </div>
    </PageLayout>
  );
}

export default ViewItem;
