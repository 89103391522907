import React, { useState, useEffect } from 'react';
import '../../styling/UserTab.css'; // Ensure this file exists and contains appropriate styles
import DisplayAllResources from '../map_displays/displayAllResources';
import LoadingWheel from '../../components/loading';
import axios from 'axios';
const UserTab = ({ user }) => {
  const [activeTab, setActiveTab] = useState('Account');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userResources, setUserResources] = useState([]);

  const openTab = (userDetails) => {
    setActiveTab(userDetails);
  };

  useEffect(() => {
    setLoading(true);
    axios.get(`${process.env.REACT_APP_API_SERVER_URL}/v1/items/user/`+user.username)
      .then(response => {
        setUserResources(response.data);
        setError(null);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>    <LoadingWheel /></div>;
  }

  return (
    <div className='container-flex'>
      <div className="tab">
        <button
          className={`tablinks ${activeTab === 'Account' ? 'active' : ''}`}
          onClick={() => openTab('Account')}
        >
          Account
        </button>
        <button
          className={`tablinks ${activeTab === 'Activity' ? 'active' : ''}`}
          onClick={() => openTab('Activity')}
        >
          Activity
        </button>
        <button
          className={`tablinks ${activeTab === 'Orders' ? 'active' : ''}`}
          onClick={() => openTab('Orders')}
        >
          Orders
        </button>
        
      </div>

      <div id="Account" className={`tabcontent ${activeTab === 'Account' ? 'show' : ''}`}>
        <h3>Account</h3>
        <p>Manage your account details here. To Update or Delete your account please use the contact us form. Passwords can be updated using the "Forgot my password" link on sign in</p>
        <p>Email: {user.email}</p>
        <p>Username: {user.username}</p>
      </div>

      <div id="Activity" className={`tabcontent ${activeTab === 'Activity' ? 'show' : ''}`}>
        <h3>Activity</h3>
        <p>View your recent activity here.</p>
        {userResources.length > 0 ? (<DisplayAllResources items={userResources} dataPassed={true} />):(<><h2>Resources</h2><p>You currently have no resources! Create one by heading to any topics page and clicking "+Resource"</p></>) }
      </div>

      <div id="Orders" className={`tabcontent ${activeTab === 'Orders' ? 'show' : ''}`}>
        <h3>Orders</h3>
        <p>Check your past orders here.</p>
      </div>
    </div>
  );
};

export default UserTab;