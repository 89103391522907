import React, { useState, useEffect } from 'react';

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [preferences, setPreferences] = useState({
    performance: false,
    advertising: false,
    analytics: false,
  });

  useEffect(() => {
    const metaTag = document.querySelector('meta[name="cookie-consent"]');
    const consent = metaTag ? metaTag.getAttribute('content') : localStorage.getItem('cookieConsent');
    const storedPreferences = JSON.parse(localStorage.getItem('cookiePreferences')) || {};

    if (consent) {
      setPreferences(prev => ({ ...prev, ...storedPreferences }));
      if (consent === 'all' || storedPreferences.advertising) {
        initializeGoogleAds();
      }
    } else {
      setShowBanner(true);
    }
  }, []);

  const initializeGoogleAds = () => {
    if (typeof window.adsbygoogle !== 'undefined') {
      try {
        if (!window.adsbygoogle) {
          (window.adsbygoogle = window.adsbygoogle || []).push({});
        }
      } catch (e) {
        console.error('Failed to initialize Google Ads:', e);
      }
    } else {
      const script = document.getElementById('google-ads-script');
      if (script) {
        script.onload = () => {
          try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
          } catch (e) {
            console.error('Failed to initialize Google Ads:', e);
          }
        };

        script.onerror = (e) => {
          console.error('Error loading Google Ads script:', e);
        };
      }
    }
  };

  const setMetaTagContent = (content) => {
    let metaTag = document.querySelector('meta[name="cookie-consent"]');
    if (!metaTag) {
      metaTag = document.createElement('meta');
      metaTag.setAttribute('name', 'cookie-consent');
      document.head.appendChild(metaTag);
    }
    metaTag.setAttribute('content', content);
  };

  const handleAcceptAll = () => {
    setMetaTagContent('all');
    localStorage.setItem('cookieConsent', 'all');
    const allPreferences = {
      performance: true,
      advertising: true,
      analytics: true,
    };
    localStorage.setItem('cookiePreferences', JSON.stringify(allPreferences));
    setPreferences(allPreferences);
    setShowBanner(false);
    initializeGoogleAds();
  };

  const handleRejectNonEssential = () => {
    setMetaTagContent('necessary');
    localStorage.setItem('cookieConsent', 'necessary');
    const minimalPreferences = {
      performance: false,
      advertising: false,
      analytics: false,
    };
    localStorage.setItem('cookiePreferences', JSON.stringify(minimalPreferences));
    setPreferences(minimalPreferences);
    setShowBanner(false);
  };

  const handleSavePreferences = () => {
    const updatedPreferences = {
      performance: document.getElementById('performance').checked,
      advertising: document.getElementById('advertising').checked,
      analytics: document.getElementById('analytics').checked,
    };
    setMetaTagContent('custom');
    localStorage.setItem('cookiePreferences', JSON.stringify(updatedPreferences));
    localStorage.setItem('cookieConsent', 'custom');
    setPreferences(updatedPreferences);
    setShowBanner(false);
    if (updatedPreferences.advertising) {
      initializeGoogleAds();
    }
    setShowSettings(false);
  };

  return (
    <>
      {showBanner && (
        <div style={{
          padding: '20px',
          backgroundColor: '#333',
          color: '#fff',
          position: 'fixed',
          bottom: '0',
          left: '0',
          right: '0',
          width: '100%',
          zIndex: '1000',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          {!showSettings ? (
            <>
              <p style={{ textAlign: 'center' }}>
                We use cookies to enhance site functionality, analyze site usage, and deliver personalized advertising. You can accept all cookies, or customize your preferences. For more information, please read our <a href="/privacy" style={{ color: '#fff', textDecoration: 'underline' }}>Privacy Policy</a>.
              </p>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <button onClick={handleAcceptAll} style={buttonStyle}>Accept All</button>
                <button onClick={handleRejectNonEssential} style={buttonStyle}>Reject Non-Essential</button>
                <button onClick={() => setShowSettings(true)} style={buttonStyle}>Customize Settings</button>
              </div>
            </>
          ) : (
            <>
              <p>Customize your cookie preferences:</p>
              <div>
                <label>
                  <input type="checkbox" checked disabled /> Essential Cookies (Always Active)
                </label>
              </div>
              <div>
                <label>
                  <input type="checkbox" id="performance" defaultChecked={preferences.performance} /> Performance Cookies
                </label>
              </div>
              <div>
                <label>
                  <input type="checkbox" id="advertising" defaultChecked={preferences.advertising} /> Advertising Cookies
                </label>
              </div>
              <div>
                <label>
                  <input type="checkbox" id="analytics" defaultChecked={preferences.analytics} /> Analytics Cookies
                </label>
              </div>
              <button onClick={handleSavePreferences} style={{ marginTop: '10px', padding: '10px 20px', backgroundColor: '#4CAF50', color: '#fff', border: 'none', borderRadius: '4px' }}>Save Preferences</button>
            </>
          )}
        </div>
      )}
    </>
  );
};

const buttonStyle = {
  padding: '10px 20px',
  backgroundColor: '#4CAF50',
  color: '#fff',
  border: 'none',
  borderRadius: '4px',
  width: '100%',
  maxWidth: '300px',
  textAlign: 'center',
};

export default CookieBanner;
