//import useState method from react library 
import { useState } from "react";
//import the styling for the page
import { Button } from "react-bootstrap";
import { Form } from "react-bootstrap";
//import axios to make API calls
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { PageLayout } from "../components/page-layout";

export default function Contact() {

  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [isMessageSent, setIsMessageSent] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  const validateEmail = (email) => {
    // Simple regex for validating an email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {

    e.preventDefault();
    setIsSubmitting(true);
    try {

      if (email.replace(" ", "") !== "" || subject.replace(" ", "") !== "" || message.replace(" ", "") !== "" || validateEmail(email) === false) { 
    
        const formData = new FormData();
        formData.append('email', email);
        formData.append('subject', subject);  
        formData.append('message', message);
        const response = await axios.post(`${process.env.REACT_APP_API_SERVER_URL}/v1/users/contact`, formData);
        
        navigate("/Contact");
        setIsMessageSent(true)
    } else {
        alert("Please provide valid input"); 
        setIsSubmitting(false);
      }
      
    } catch {
      alert("Could not submit form - please try again later")
      setIsSubmitting(false);
    }

    

  };

  return (
    <PageLayout>
    <div className='container'>
    {isMessageSent ? (
      <div id="submitted" style={{padding:"2%"}}>
        <h2>Your message has been submitted.</h2>
        <p>Thank you for contacting us! If appropriate to your message; we will get back to you as soon as possible.</p>
      </div> 
    ) : (
    <Form onSubmit={handleSubmit} id="contactForm">
    <h1>Contact Us</h1>
    <p>Use this form to send us a message or request an action such as update/delete an account, request data, or unsubscribe from the mail list!</p>
    <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Label>Email address</Form.Label>
      <Form.Control type="email" placeholder="Enter email" onChange={(e) => setEmail(e.target.value)} required/>
      <Form.Text className="text-muted">
        We'll never share your email with anyone else.
      </Form.Text>
    </Form.Group>

    <Form.Group className="mb-3" controlId="formBasicSubject">
      <Form.Label>Subject</Form.Label>
      <Form.Control type="text" placeholder="Subject" onChange={(e) => setSubject(e.target.value)} required />
    </Form.Group>

    <Form.Group className="mb-3" controlId="formBasicMessage">
      <Form.Label>Message</Form.Label>
      <Form.Control type="text" placeholder="Message" onChange={(e) => setMessage(e.target.value)} required />
    </Form.Group>

    <Button variant="primary" type="submit" disabled={isSubmitting}>
      Contact Us
    </Button>
  </Form>
    )}

  </div>
</PageLayout>
  );
}
