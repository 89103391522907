import React from 'react';
import { FaSpinner } from 'react-icons/fa';
import '../styling/LoadingWheel.css'; 

const LoadingWheel = ({ size = 50 }) => {
  return (
    <div className="loading-wheel">
      <FaSpinner className="spinner" size={size} />
    </div>
  );
};

export default LoadingWheel;
