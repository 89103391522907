import React, { useEffect, useState } from 'react';
import axios from 'axios';
import "../../styling/ViewTopic.css"
import DisplayAllResources from '../../components/map_displays/displayAllResources';
import LoadingWheel from '../../components/loading';
import HeroImage from '../../components/hero';
import { PageLayout } from "../../components/page-layout";

function ViewTopic() {
  const id = window.location.pathname.split('/').reverse()[0];
  const [loading, setLoading] = useState(false);
  const [topics, setTopics] = useState([]);
  const [topicName, setTopicName] = useState('');
  const [topicDesc, setTopicDesc] = useState('');
  const [topicImage, setTopicImage] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    axios.get(`${process.env.REACT_APP_API_SERVER_URL}/v1/items/topic/`+id)
      .then(response => {
        setTopics(response.data);
        setError(null);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);


  useEffect(() => {
    setLoading(true);
    axios.get(`${process.env.REACT_APP_API_SERVER_URL}/v1/topics/`+id)
      .then(response => {
        setTopicName(response.data.name);
        setTopicDesc(response.data.description);

        const item = response.data;
        setTopicImage(item.images[0].file);        
        setError(null);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);


  if (loading) {
    return <div>    <LoadingWheel /></div>;
  }
  return (
    <PageLayout>
    <div className="container-flex">
      <HeroImage imagepath={topicImage} header={topicName} subheader={topicDesc} pad='6%'></HeroImage>
      <br />
      {topics.length > 0 ? (<DisplayAllResources items={topics} dataPassed={true}/>):(<><h2>Resources</h2><p>Sorry, we cant find any resources for this topic!</p></>) }


    </div>
    </PageLayout>
  );
}

export default ViewTopic;