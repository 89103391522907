import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import { LoginButton } from "../buttons/login-button";
import { LogoutButton } from "../buttons/logout-button";
import { SignupButton } from "../buttons/signup-button";
import { useAuth0 } from "@auth0/auth0-react";
import { FaRegUser, FaShoppingBasket } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios';

export default function NavbarJS() {

  const { user } = useAuth0();
  const { isAuthenticated } = useAuth0();

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const trackingId = queryParams.get('tId');
    var trackAnon = localStorage.getItem("trackAnon", trackingId)
    if (trackingId && !trackAnon) {
      logAnonTrackingData(trackingId);
      localStorage.setItem("trackAnon", trackingId)
    }
    if(trackAnon && isAuthenticated) {
      AnonCreatedUserFromLink(trackAnon);
      localStorage.removeItem("trackAnon")
    }
  }, [isAuthenticated, location]);


  const AnonCreatedUserFromLink = async (trackingId) => {
    try {
      const formData = new FormData();
      formData.append('trackId', trackingId);
      
      await axios.post(`${process.env.REACT_APP_API_SERVER_URL}/v1/analytics/user/createdfromlink`, formData)
    } catch (error) {
      console.log("Analytics ID is not in use")
    } 
  }
  
  const logAnonTrackingData = async (trackingId) => {
    try {
      const formData = new FormData();
      formData.append('trackId', trackingId);
      formData.append('messageType', 1);
      await axios.post(`${process.env.REACT_APP_API_SERVER_URL}/v1/analytics/create/clickedlink`, formData)
    } catch (error) {
      console.log("Analytics ID is not in use")
    }    
  };
  
  return (
    <>
    <Navbar expand="lg" className="bg-body-tertiary main-nav">
      <Container fluid>
        
        <Navbar.Brand href="/">SEN Resource</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
          >
            <Nav.Link href="/topics">Resources</Nav.Link>
            <Nav.Link href="/community" disabled>Community</Nav.Link>
            <Nav.Link href="/blog" disabled>Blog</Nav.Link>            
            <NavDropdown title="Tools" id="navbarScrollingDropdown">
                <NavDropdown.Item href="/tools" disabled>All Tools</NavDropdown.Item>
                <NavDropdown.Divider />
              <NavDropdown.Item href="/tool/profile-creator" disabled>SEN Profile Builder</NavDropdown.Item>
              <NavDropdown.Item href="/tool/aqtest">AQ Autism Assessment</NavDropdown.Item>
              <NavDropdown.Item href="/tool/profile-creator" disabled>Games</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/shop" disabled>Shop</Nav.Link> 



        </Nav>


        {!isAuthenticated && (
        <>
          <SignupButton />{'\u00A0'}{'\u00A0'}
          <LoginButton />
        </>
      )}
      {isAuthenticated && (
        <>

<NavDropdown className="dropdown-menu-end pull-right" style={{maxWidth:"fit-content",padding:"5px", textAlign:"center"}} title={
          <img
            src={user.picture} 
            alt="Profile"
            style={{
              width: "30px",
              height: "30px",
              borderRadius: "50%",
            }}
          />
        } id="navbarScrollingDropdownUser" drop="down" align="end">
          
          <span style={{padding:"4px 10px"}}>{user.username}</span>
          <NavDropdown.Divider />
          <NavDropdown.Item href="/profile"><FaRegUser />  Profile</NavDropdown.Item>
          <NavDropdown.Divider />
          <LogoutButton />
        </NavDropdown>
        </>
        
      )}

        </Navbar.Collapse>
      </Container>
    </Navbar>
    </>
  );
}


//        </Container><Link href="/basket" style={{padding:"5px", textAlign:"center", marginRight:"5px",fontSize:"1.5rem", color:"black"}}>
//<FaShoppingBasket />
//</Link>
