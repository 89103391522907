//import React, useState, useRef, useEffect methods of the react library
import React, { useState, useRef, useEffect } from 'react';
//import axios to make API calls
import axios from 'axios';
//import the form css styling
import '../../styling/popUpForm.css';
import { useAuth0 } from '@auth0/auth0-react';


//create and export the PopUpFormItem component
//isOpen, and onClose is passed as a parameter
const PopupForm = ({ isOpen, onClose }) => {
    //create the get and set variables for the form inputs 
  const [topicName, setTopicName] = useState('');
  const [topicDescription, setTopicDescription] = useState('');
  const [topicImage, setTopicImage] = useState(null); 
    //declare formRef and set it to null
  const formRef = useRef(null);


    //handle the opening and closing of the pop up form
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (formRef.current && !formRef.current.contains(e.target)) {
        onClose();
      }
    };

    //if the pop up form is already open 
    if (isOpen) {
            //add an event listener to the document to handle the window onclick
      document.addEventListener('mousedown', handleOutsideClick);
    }
    //remove the event listener for the window if the form is not open
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen, onClose]);

  const { getAccessTokenSilently } = useAuth0();

    //handle the submission of the form
  const handleSubmit = async (e) => {
    //prevent the form from submitting and stop the page refreshing on submit
    e.preventDefault();

    //try catch block
    try {
      const accessToken = await getAccessTokenSilently();
      const formData = new FormData();
      formData.append('topicImage', topicImage);
      formData.append('topicName', topicName);
      formData.append('topicDescription', topicDescription);

              //create an API call to post the data
      const response = await axios.post(`${process.env.REACT_APP_API_SERVER_URL}/v1/topics`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${accessToken}`,
        }
      });
      
      //log to the console if the form is submitted
      //reload the page 
      window.location.reload();
      //close the pop up form
      onClose();

    } 
        //if there is an error submitting the form 
    catch (error) {
      //log the error
      //alert to the user
      alert('Error submitting form');
    }
  };
  //return the HTML content
  return (
    <div className={`popup ${isOpen ? 'open' : ''}`}>
      <div className="popup-inner" ref={formRef}>
        <h2>Add Topic</h2>        
        <button className="close-btn" onClick={onClose}>X</button>
        
        
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="topicName">Topic Name:</label>
            <input name="topicName" type="text" id="topicName" value={topicName} onChange={(e) => setTopicName(e.target.value)} required/>
          </div>
          <div className="form-group">
            <label htmlFor="topicDescription">Topic Description:</label>
            <input name="topicDescription" type="topicDescription" id="topicDescription" value={topicDescription} onChange={(e) => setTopicDescription(e.target.value)} required/>
          </div>
          <div className="form-group">
            <label htmlFor="image1">Image:</label>
            <input type="file" id="image1" name="image1" accept="image/*" onChange={(e) => setTopicImage(e.target.files[0])} required />
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default PopupForm;
