import React, { useEffect, useRef, useState } from 'react';

const PdfEmbed = ({ pdfUrl, showAllPages = false }) => {
  return (

    <object data={"https://docs.google.com/gview?embedded=true&url="+pdfUrl} type="application/pdf" width="100%" height="100%">
        <embed src={"https://docs.google.com/gview?embedded=true&url="+pdfUrl} width="100%" height="100%"/> 
    </object>
    
  );
};

const styles = {
  container: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
};

export default PdfEmbed;
