import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Button } from "react-bootstrap";
export const SignupButton = () => {
  const { loginWithRedirect } = useAuth0();

  const handleSignUp = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/profile",
      },
      authorizationParams: {
        screen_hint: "signup",
      },
    });
  };

  return (
    <Button className="pull-right" onClick={handleSignUp} style={{marginLeft:"5px", borderRadius: "50px", padding: "4px 24px", marginRight: "4px"}}>
      Sign Up
    </Button>
  );
};