import React from 'react';

export default function HeroImage({ imagepath, header, subheader, pad }) {

    return (
    <header style={{ paddingLeft: 0 }}>
      <div
        className='text-center bg-image'
        style={{ backgroundImage: `url(${imagepath})`, backgroundSize: "cover", backgroundPosition: "center"}}
      >
        <div className='mask' style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)', padding: pad }}>
          <div className='d-flex justify-content-center align-items-center h-100'>
            <div className='text-white'>
              <h1 className='mb-3'>{header}</h1>
              <h4 className='mb-3'>{subheader}</h4>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}