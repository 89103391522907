import { useAuth0 } from "@auth0/auth0-react";
import { PageLoader } from "./components/page-loader";
import { AuthenticationGuard } from "./components/auth/authentication-guard";
import './styling/main.css';
import { Route, Routes } from "react-router-dom";
import NotFoundPage from "./pages/error/404";
import Resources from "./pages/content/Resources";
import Topics from './pages/content/Topics';
import ViewResource from './pages/content/ViewResource';
import ViewTopic from './pages/content/ViewTopic';
import Landing from './pages/Landing';
import TopicsAdd from './pages/admin/TopicsAdd';
import ResourcesAdd from './pages/admin/ResourcesAdd';
import Contact from './pages/Contact';
import About from './pages/About';
import ComingSoonPage from './pages/error/ComingSoon'
import Privacy from './pages/user/Privacy';
import ProfileBuilder from "./pages/tools/ProfileBuilder";
import Tools from "./pages/tools/Tools";
import Terms from './pages/user/Terms';
import { ProfilePage } from "./pages/user/Profile"  
import AQTest from "./pages/tools/AQ";
import '@fontsource/montserrat';



function App() {
  
  const { isLoading } = useAuth0();

  if (isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }
  return (
        <Routes>

            <Route exact path="/" element={<Landing />} />
            <Route exact path="/Topics" element={<Topics />} />

            <Route exact path="/Resources" element={<Resources />} />
            <Route path="/resource/:resourceID" element={<ViewResource />} />
            <Route path="/topic/:topicID" element={<ViewTopic />} />
          
            <Route exact path="/Topics/Create" element={<AuthenticationGuard component={TopicsAdd} />} />
            <Route exact path="/Resources/Create" element={<AuthenticationGuard component={ResourcesAdd} />} />

            <Route exact path="/Contact" element={<Contact />} />
            <Route exact path="/About" element={<About />} />
            <Route exact path="/Privacy" element={<Privacy />} />
            <Route exact path="/Terms" element={<Terms />} />
            <Route exact path="/basket" element={<ComingSoonPage />} />

            <Route exact path="/tools" element={<AuthenticationGuard component={Tools} />} />
            <Route exact path="/tool/profile-creator" element={<AuthenticationGuard component={ProfileBuilder} />} />
            <Route exact path="/tool/aqtest" element={<AQTest />} />
            
            <Route exact path="/blog" element={<ComingSoonPage />} />
            <Route exact path="/community" element={<ComingSoonPage />} />
            <Route exact path="/shop" element={<ComingSoonPage />} />

            <Route
              path="/profile"
              element={<AuthenticationGuard component={ProfilePage} />}
            />


          {/* 404 page if none of the roots match the user request */}
          <Route path="*" element={<NotFoundPage />} />

        </Routes>

  );
}



export default App;
