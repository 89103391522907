import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import Button from 'react-bootstrap/Button';

export const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/profile",
      },
    });
  };

  return (
    <Button className="pull-right" onClick={handleLogin} style={{marginLeft:"5px", borderRadius: "50px", padding: "4px 24px", marginRight: "4px"}}>
      Log In
    </Button>
  );
};